import { userDeviceConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function getUserDevice(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "POST",
        headers: headers
    };

    return fetch(userDeviceConfig.getUserDeviceEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function removeUserDevice(accessToken, uuid) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ uuid: uuid })
    };
    console.log('accessToken: ' + accessToken);
    console.log('UUID: ' + uuid);
    return fetch(userDeviceConfig.removeUserDeviceEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}