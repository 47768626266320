import React from 'react';
import { PageLayout } from './components/PageLayout';
import { UserDeviceContent } from './components/UserDeviceContent';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { useIsAuthenticated } from '@azure/msal-react';

import './App.css';
/**
 * If a user is authenticated the UserDeviceContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
    const { accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    return (
        <div className="App">
            {
                isAuthenticated ? 
                <AuthenticatedTemplate>
                    <div className="greeting">
                        <h4>Hi {accounts[0].name} !</h4>
                    </div>
                    
                    <div className="title">
                        <p>See when and where you're signed in to HKU App.</p>
                    </div>
                    <UserDeviceContent />
                </AuthenticatedTemplate>
                :
                <UnauthenticatedTemplate>
                    <div className="title">
                        <h5>Please sign in to check your active sessions on the HKU App.</h5>
                    </div>
                </UnauthenticatedTemplate>
            }
        </div>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
