import React, { useState, useEffect } from 'react';
import { loginRequest } from '../authConfig';
import { useMsal } from '@azure/msal-react';
import { getUserDevice, removeUserDevice } from '../userDevice';
import { Button, Modal } from 'react-bootstrap';
import { ReactComponent as PhoneIcon } from 'bootstrap-icons/icons/phone.svg';
import { ReactComponent as LogoutIcon } from 'bootstrap-icons/icons/box-arrow-left.svg';

/**
 * Renders information of a user device which signed in HKU App
 */
export const UserDeviceContent = () => {
    const { instance, accounts } = useMsal();
    const [userDeviceData, setUserDeviceData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [removing, setRemoving] = useState(false);
    const [removeResult, setRemoveResult] = useState("");
    const [showRemoveButton, setShowRemoveButton] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const handleConfirmModalClose = () => setShowConfirmModal(false);
    const handleConfirmModalShow = () => setShowConfirmModal(true);
    const [showResultModal, setShowResultModal] = useState(false);
    const handleResultModalClose = () => setShowResultModal(false);
    const handleResultModalShow = () => setShowResultModal(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                instance.acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                })
                .then((response) => {
                    getUserDevice(response.accessToken).then((response) => {
                        if(response){
                            setUserDeviceData(response);
                            if(response.device_name) {
                                setShowRemoveButton(true);
                            }
                            else {
                                setShowRemoveButton(false);
                            }
                        }
                        else {
                            setError('Failed to retrieve user device data.');
                        }
                        setLoading(false);
                    });
                });
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchData();
    }, [instance, accounts]);

    const removeDevice = () => {
        try {
            setRemoving(true);
            instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                removeUserDevice(response.accessToken, userDeviceData.uuid).then((response) => {
                    console.log(response);
                    if(response && response.remove === "success"){
                        setRemoveResult("You have signed out HKU App from the device successfully.");
                        setUserDeviceData(null);
                        setShowRemoveButton(false);
                    }
                    else{
                        setRemoveResult("Failed to sign out HKU App on the device. Please try again. If problem still exists, please contact ITS Service Desk.");
                        setShowRemoveButton(true);
                    }
                    handleResultModalShow();
                    setRemoving(false);
                });
            });
        } catch (error) {
            setError(error.message);
        }
        handleConfirmModalClose();
    }

    return (
        <>
            <Modal className='confirmModal' show={showConfirmModal} onHide={handleConfirmModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm To Sign Out</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to sign out HKU App from this device?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleConfirmModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={removeDevice}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className='resultModal' show={showResultModal} onHide={handleResultModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Result</Modal.Title>
                </Modal.Header>
                <Modal.Body>{removeResult}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleResultModalClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="userDevice">
                {loading && <div className="spinner-border text-success" role="status"></div>}

                {error && <p className='error'>Error: {error}</p>}

                {userDeviceData && userDeviceData.device_name ?
                    <div className="container">
                        <div className="device-info-row">
                            <PhoneIcon className="icon-size" />
                            <div className="device-info">
                                <p className="device-name">{userDeviceData.device_name}</p>
                                <p className="login-time">Login: {userDeviceData.timestamp}</p>
                            </div>
                        </div>
                        {showRemoveButton && 
                            <div className='btn-row'>
                                <Button className='remove-device-btn' onClick={handleConfirmModalShow} disabled={removing} variant="primary"><LogoutIcon/> Sign Out</Button>
                            </div>
                        }
                    </div>
                    :
                    <>{!loading && <div className="container"><strong>You have not signed in to HKU App on any device.</strong></div>}</>
                }

            </div>
        </>
    );
};